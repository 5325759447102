<template>
  <!-- 未签约 -->
  <div id="enterpriseServiceInquiry">
    <div class="result-panel">
      <CSTable>
        <template v-slot:thead>
          <tr>
            <th class="text-center">拒签时间</th>
            <th class="text-center">项目名称</th>
            <th class="text-center">服务提供方</th>
            <th class="text-center">抽成比例</th>
            <th class="text-center">用户信息</th>
            <th class="text-center">操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="isLoading">
            <tr v-for="(log, index) in list" :key="index">
              <td class="text-center date-td">
                {{ log.refuseSignTime || "-" }}
              </td>
              <td class="text-center">
                {{ log.itemName }}
              </td>
              <template>
                <td class="text-center">
                  <p style="margin-bottom: 0">
                    {{ log.providerName || "物业" }}
                  </p>
                  <p style="margin-bottom: 0" v-if="log.providerContact">
                    {{ log.providerContact }}
                  </p>
                  <p v-if="log.providerPhone">
                    {{ log.providerPhone }}
                  </p>
                </td>
              </template>
              <td class="text-center">
                {{ log.commissionScale ? log.commissionScale + "%" : "-" }}
              </td>
              <td class="text-center" v-if="log.userName">
                <p style="margin-bottom: 0">
                  {{ log.userName }}
                  【{{ log.userPhone }}】
                </p>
                <p style="margin-bottom: 0" v-if="log.userCompany">
                  {{ log.userCompany }}
                </p>
                <p style="margin-bottom: 0" v-if="log.userCompanyAddr">
                  {{ log.userCompanyAddr }}
                </p>
              </td>

              <td class="text-center">
                <div class="btn-group">
                  <button
                          type="button"
                          class="btn btn-primary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                  >
                    操作
                  </button>
                  <ul
                          class="dropdown-menu"
                          style="min-width: 76px; width: 76px; padding: 0"
                  >
                    <li>
                      <a style="width: 100%; padding: 3px 0"
                         @click="
                      signContractParams.id = log.id;
                      signDialogVisible = true;"
                      >
                        签约项目
                      </a>
                    </li>
                  </ul>
                </div>
<!--                <div class="btn-group">-->
<!--                  <el-button-->
<!--                    type="button"-->
<!--                    class="btn btn-primary"-->
<!--                    style="background-color: #1ab394; color: #fff"-->
<!--                    @click="-->
<!--                      signContractParams.id = log.id;-->
<!--                      signDialogVisible = true;-->
<!--                    "-->
<!--                  >-->
<!--                    签约项目-->
<!--                  </el-button>-->
<!--                </div>-->

              </td>
            </tr>
          </template>
          <tr v-else>
            <td :colspan="12">
              <div style="text-align: center">暂无数据</div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination> </Pagination>
    </div>

    <!-- 点击签约项目的弹出框 -->
    <CSDialog
      dialogTitle="签约项目"
      dialogWidth="708px"
      dialog-confirm-btn-text="确定"
      :dialogVisible="signDialogVisible"
      @onClose="signDialogVisible = false"
      @onConfirm="signContract"
    >
      <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
        <div class="box_padding">
          <div>
            <span style="margin-right: 40px; font-size: 24px">服务日期</span>
            <span>
              <span style="margin-right: 10px">
                <input
                  type="radio"
                  v-model="signContractParams.type"
                  :value="1"
                  checked="checked"
                />
                <i class="inputSpace">单次</i>
              </span>
              <span>
                <input
                  type="radio"
                  v-model="signContractParams.type"
                  :value="2"
                />
                <i class="inputSpace">周期</i>
              </span>
            </span>
          </div>
          <div style="margin-top: 16px; margin-left: 135px">
            <CSSelect height="40px" i-width="36px">
              <el-date-picker
                v-model="signContractParams.startDate"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="请选择"
                style="width: 200px; font-size: 24px; line-height: 1"
                :clearable="false"
              >
              </el-date-picker>
            </CSSelect>
            <template v-if="signContractParams.type === 2">
              <span style="margin: 0 10px">-</span>
              <CSSelect height="40px" i-width="36px">
                <el-date-picker
                  v-model="signContractParams.endDate"
                  format="yyyy-MM-dd "
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="请选择"
                  style="width: 200px; font-size: 24px; line-height: 1"
                  :clearable="false"
                >
                </el-date-picker>
              </CSSelect>
            </template>
          </div>
        </div>
        <div class="box_padding">
          <span style="margin-right: 40px; font-size: 24px">应付金额</span>
          <i style="font-size: 24px">
            <input
              type="text"
              placeholder="1-999999"
              style="
                width: 130px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #979797;
              "
              v-model="signContractParams.payableMoney"
            />
            元
          </i>
        </div>
      </div>
    </CSDialog>
  </div>
</template>

<script>
import { getadminQueryOrderUrl, signContractUrl } from "@/requestUrl";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import Pagination from "@/components/Pagination";
import CSTable from "@/components/common/CSTable";

const MEETING_ROOM_TYPE = {
  PUBLIC: 2,
  PRIVATE: 1,
};
export default {
  name: "enterpriseServiceInquiry",
  components: {
    CSTable,
    CSSelect,
    CSDialog,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      signDialogVisible: false,
      signContractParams: {
        id: "",
        startDate: "",
        endDate: "",
        type: 1,
        payableMoney: "",
      },
      // 订单返回来的数据
      list: [],

      // 控制不签约项目弹出框的显示与隐藏
      noSigning: false,
    };
    MEETING_ROOM_TYPE;
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.queryItem();
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.queryItem(pageNo);
    });
  },
  methods: {
    // 查询询价中的数据
    queryItem(pageNo = 1, pageSize = 10) {
      this.$fly
        .post(getadminQueryOrderUrl, {
          regionId: this.$vc.getCurrentRegion().communityId, //区域id
          state: 3, //1.询价中2.已签约3.不签约4.已退款
          pageNo,
          pageSize,
        })
        .then((res) => {
          if(res.data.datas.length == 0){
               this.isLoading = false;
          }
          this.list = res.data.datas;
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize,
            });
          }
        });
    },
    // 签约项目事件
    signContract() {
      this.$fly
        .post(signContractUrl, {
          regionId: this.$vc.getCurrentRegion().communityId, //区域id 必填
          id: this.signContractParams.id, //订单id 必填
          sign: 1, //1.签约0.不签约 必填
          serviceDateType: this.signContractParams.type, //服务日期类型 1.单次2.周期
          serviceDate:
            this.signContractParams.startDate +
            (this.signContractParams.type == 2
              ? "~" + this.signContractParams.endDate
              : ""), //服务日期
          payableMoney: this.signContractParams.payableMoney * 100, //应付金额 单位分
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          this.$vc.toast("签约成功");
          this.signDialogVisible = false;
          this.queryItem();
        });
    },
  },
};
</script>

<style scoped>
#enterpriseServiceInquiry .el-dialog__header {
  padding: 0;
  height: 0;
}
/*#enterpriseServiceInquiry .el-input--prefix .el-input__inner {
    width: 150px;
}*/
.el-input__inner::placeholder {
  color: #999;
}
#enterpriseServiceInquiry .el-dialog__body {
  padding: 20px 0 20px 30px;
}
#enterpriseServiceInquiry .el-dialog__footer {
  padding-top: 20px;
  text-align: center;
}
.signBox > el-dialog__footer {
  border-top: 1px solid #999;
}
.el-dialog .cancel {
  width: 80px;
  height: 40px;
  background: #ff9f00;
  color: #fff;
  font-size: 20px;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  padding: inherit;
  margin-right: 30px;
}
.el-dialog .confirm {
  width: 80px;
  height: 40px;
  color: #fff;
  background: #1ab394;
  font-size: 20px;
  padding: inherit;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
}
#enterpriseServiceInquiry .el-card__body {
  padding: 3px 0 0px 0;
}
</style>

<style scoped>
.text-center {
  padding-bottom: 10px;
  vertical-align: middle;
}
.box_padding {
  margin-bottom: 30px;
}
i {
  font-style: normal;
}
input[type="radio"] {
  cursor: pointer;
  position: relative;
  outline: none;
  margin-right: 5px;
}
</style>
